.App {
  text-align: center;
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbarStyle {
  padding: .5rem 1rem !important;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
}

.login_div {
  width: 100%;
  padding: 0 8px;
  font-weight: bold;
}
.profileContent {
  padding: 8px;
}
